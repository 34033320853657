import React from 'react'
import {Markdown} from 'react-showdown'
import DefaultLayout from '../components/layouts/defaultLayout'
import {useStaticQuery, graphql} from 'gatsby'
import {FacebookProvider, Page} from 'react-facebook'
import InstagramEmbed from 'react-instagram-embed'
import Footer from '../components/footer/footer'
import Container from '../components/utils/container'
import {AProposWrapper} from '../components/pages/aProposWrapper'
import LogoHero from '../components/hero/logoHero'
import { Row, Col } from 'react-flexbox-grid'
import SEO from '../components/utils/SEO'
import theme from '../theme/theme'
import Hero from '../components/hero/hero'

const APropos = () => {
    const {strapiAPropos} = useStaticQuery(graphql`
    query APropos {
        strapiAPropos {
            contenu
        }
    }
  `)

    return(
        <DefaultLayout>
            <SEO title="A propos - Tribal Cabane" description="A propos de Tribal Cabane, contactez-nous, acceder à nos différents réseau sociaux. Branchons-nous !"></SEO> 
            <Hero 
                color={theme.colors.contact}
            />
            <AProposWrapper>
                <Container>
                    <Markdown className="has-text-left" markup={strapiAPropos.contenu} />
                    <Row>
                        <Col md={12} lg={6}>
                            <InstagramEmbed url="https://www.instagram.com/p/B3m01LBp37L/" maxWidth="100%" hideCaption={false} />
                        </Col>
                        <Col md={12} lg={6}>
                            <FacebookProvider appId="208791517037332">
                                <Page href="https://www.facebook.com/Tribal-Cabane-103686487934821" tabs="timeline" />
                            </FacebookProvider>
                        </Col>
                    </Row>
                   
                </Container>          
            </AProposWrapper>
        <Footer />
        </DefaultLayout>
    )    
}

export default APropos